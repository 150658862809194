<template>
  <div>
    <v-card>
      <v-card-title>Требования к подготовке кромок</v-card-title>
      <v-card-text>
        <v-textarea 
          auto-grow
          row-height="30"
          v-model="cardContent"
        ></v-textarea>
        <Files></Files>
      </v-card-text>
      
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Files from "@/modules/gostCard/views/components/Files";
export default {
  data() {
    return {
      cardContent: "",
      
    };
  },
  components: {
    Files,
  },
  computed: {
    ...mapState("gostCard", { card: "newCard" }),
  },
  mounted() {
    this.$axios
      .get("/sapr-gost/card/demands", {
        params: {
          viewEl_1: this.card.element_1.viewEl.id,
          viewEl_2: this.card.element_2.viewEl.id,
          groupEl_1: this.card.element_1.groupEl.id,
          groupEl_2: this.card.element_2.groupEl.id,
          seamId: this.card.seamAndView.seamId,
        },
      })
      .then((response) => {
        this.cardContent = response.data.description;
      });
  },
  
};
</script>
