var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Характеристика соединения и свариваемых элементов ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.methodItems,"item-text":"name","label":"способ сварки","dense":"","return-object":""},on:{"input":_vm.getGostItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+"-"+_vm._s(item.description)+" ")]}}])})],1),_c('v-col',[_c('v-select',{attrs:{"return-object":"","items":_vm.gostItems,"item-text":"name","label":"ГОСТ","dense":""},on:{"input":_vm.getJointItems}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.jointItems,"item-text":"name","label":"тип соединения","dense":"","return-object":""},on:{"input":_vm.getTypeAndView}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"тип шва","readonly":"","dense":""},model:{value:(_vm.seamAndType.seamName),callback:function ($$v) {_vm.$set(_vm.seamAndType, "seamName", $$v)},expression:"seamAndType.seamName"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"вид соединения","readonly":"","dense":""},model:{value:(_vm.seamAndType.jointName),callback:function ($$v) {_vm.$set(_vm.seamAndType, "jointName", $$v)},expression:"seamAndType.jointName"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.jointPositonItems,"item-text":"name","label":"полложение стыка","dense":""},on:{"input":function($event){return _vm.setCard({ jointPosition:{name: $event}})}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{staticClass:"tooltip",attrs:{"width":"200"}},[_vm._v(_vm._s(item.description))])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }