var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Характеристика свариваемых элементов")]),_c('v-card-text',[_c('div',{staticClass:"inner_image"},[_c('div',{staticClass:"image"},[_c('div',{staticClass:"icon"},[_c('v-icon',{staticClass:"primary--text",attrs:{"x-large":""}},[_vm._v("mdi-file-image-outline")])],1),_c('img',{attrs:{"src":_vm.image}})])]),_c('div',{staticClass:"inner__inputs"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"input__header"},[_vm._v("Характеристика элемента №1")]),_c('div',[_c('v-text-field',{attrs:{"outlined":"","label":"Тип элемента №1"},on:{"input":function($event){return _vm.setCardElement_1({ typeEl: $event })}}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.viewItems,"item-text":"name","label":"Вид детали эелемента №1","outlined":"","return-object":""},on:{"input":_vm.hideDOrS_1},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{staticClass:"tooltip",attrs:{"width":"200"}},[_vm._v(_vm._s(item.description))])])],1)]}}]),model:{value:(_vm.view_1),callback:function ($$v) {_vm.view_1=$$v},expression:"view_1"}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"марка материала  №1","placeholder":"Введите марку стали(сплава)","outlined":""},on:{"input":function($event){return _vm.setCardElement_1({ markMetal: $event })}}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.groupItems,"item-text":"name","label":"Группа материала элемента  №1","return-object":"","outlined":""},on:{"input":function($event){return _vm.setCardElement_1({ groupEl: $event })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+"-"+_vm._s(item.description)+" ")]}}])})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Диаметр элемента №1 (D1), мм ","type":"number","outlined":"","disabled":_vm.hideDiametr_1},on:{"input":function($event){return _vm.setCardElement_1({ d: $event })}}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Толщина элемента №1 (S), мм","type":"number","outlined":"","disabled":_vm.hideFat_1},on:{"input":function($event){return _vm.setCardElement_1({ s: $event })}}})],1),_c('div',[(this.card.gost.id == 16 || this.card.gost.id == 14)?_c('v-text-field',{attrs:{"label":"Угол элемента №1","type":"number","outlined":"","disabled":_vm.hideFat_1},on:{"input":function($event){return _vm.setCardElement_1({ corner: $event })}}}):_vm._e()],1)]),_c('v-col',[_c('div',{staticClass:"input__header"},[_vm._v("Характеристика элемента №2")]),_c('div',[_c('v-text-field',{attrs:{"outlined":"","label":"Тип элемента №2"},on:{"input":function($event){return _vm.setCardElement_2({ typeEl: $event })}}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.viewItems,"item-text":"name","label":"Вид детали эелемента №2","outlined":"","return-object":""},on:{"input":_vm.hideDOrS_2},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{staticClass:"tooltip",attrs:{"width":"200"}},[_vm._v(_vm._s(item.description))])])],1)]}}]),model:{value:(_vm.view_2),callback:function ($$v) {_vm.view_2=$$v},expression:"view_2"}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"марка материала №2","placeholder":"Введите марку стали(сплава)","outlined":""},on:{"input":function($event){return _vm.setCardElement_2({ markMetal: $event })}}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.groupItems,"item-text":"name","label":"Группа материала элемента №2","return-object":"","outlined":""},on:{"input":function($event){return _vm.setCardElement_2({ groupEl: $event })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+"-"+_vm._s(item.description)+" ")]}}])})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Диаметр элемента №2 (D2), мм","type":"number","outlined":"","disabled":_vm.hideDiametr_2},on:{"input":function($event){return _vm.setCardElement_2({ d: $event })}}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Толщина элемента №2 (S1), мм","type":"number","outlined":"","disabled":_vm.hideFat_2},on:{"input":function($event){return _vm.setCardElement_2({ s: $event })}}})],1),_c('div',[(this.card.gost.id == 16 || this.card.gost.id == 14)?_c('v-text-field',{attrs:{"label":"Угол элемента №2","type":"number","outlined":""},on:{"input":function($event){return _vm.setCardElement_2({ corner: $event })}}}):_vm._e()],1)])],1),_c('v-snackbar',{attrs:{"timeout":"-1","top":"","color":"error"},model:{value:(this.card.error),callback:function ($$v) {_vm.$set(this.card, "error", $$v)},expression:"this.card.error"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(_vm._s(_vm.card.errorMessege))]),_c('div',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.card.error = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }