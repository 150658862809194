<template>
  <v-card>
    <v-card-title class="justify-center">
      Характеристика соединения и свариваемых элементов
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            :items="methodItems"
            item-text="name"
            label="способ сварки"
            dense
            @input="getGostItems"
            return-object
            
          >
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.name }}-{{ item.description }}
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-select
            @input="getJointItems"
            return-object
            :items="gostItems"
            item-text="name"
            label="ГОСТ"
            dense
           
          >
          </v-select>
        </v-col>
        <v-col>
          <v-select
            :items="jointItems"
            item-text="name"
            label="тип соединения"
            dense
            return-object
            @input="getTypeAndView"
           
          >
          </v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="seamAndType.seamName"
            label="тип шва"
            readonly
            dense
           
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="seamAndType.jointName"
            label="вид соединения"
            readonly
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            :items="jointPositonItems"
            item-text="name"
            label="полложение стыка"
            dense
            @input="setCard({ jointPosition:{name: $event}})"
          >
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              <span>{{ item.name }}</span>
              <v-spacer></v-spacer>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="primary--text"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span width="200" class="tooltip">{{
                    item.description
                  }}</span>
                </v-tooltip>
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      
    </v-card-text>
  </v-card>
  
</template>


<script>
import { mapState, mapMutations} from 'vuex'

export default {

  data() {
    return {
      welMethodId: Number,
      gostId: Number,
      joinTypeId: Number,
      typeConnectionId: Number,
      gostItems: [],
      methodItems: [],
      jointItems: ["сначала выберете ГОСТ"],
      jointType: "",
      connectionView: "ос(бп)",
      jointPositonItems: [],
      seamAndType: {},
     
      
     
     
    };
  },
 
      computed: {
    ...mapState('gostCard', { card: 'newCard' }),
  },
  

  mounted() {
    this.$axios.get("/sapr-gost/card/weld-method").then((response) => {
      this.methodItems = response.data;
    });
  },
  methods: {
    test(){
      console.log(this.card)
    },
     ...mapMutations('gostCard', {
      setCard: 'setNewCardField',
      setCardElement: 'setNewCardElementField',
    }),
    getGostItems(methodItem) {
      this.welMethodId = methodItem.id;
      this.$axios
        .get("/sapr-gost/card/gost", {
          params: {
            id: this.welMethodId,
          },
        })
        .then((response) => {
          this.gostItems = response.data.gostsNames;
          this.setCard({ weldMethod:{name: methodItem.name, id: methodItem.id}})
        });
    },
    getJointItems(gostItem) {
      this.gostId = gostItem.id;

      this.$axios
        .get("/sapr-gost/card/joint", {
          params: {
            weldmethodId: this.welMethodId,
            gostId: this.gostId,
          },
        })
        .then((response) => {
          this.jointItems = response.data;
          this.setCard({ gost:{name: gostItem.name, id: gostItem.id}})
        });
    },
    getTypeAndView(joinTypeItem) {
      this.joinTypeId = joinTypeItem.id;
      this.$axios
        .get("/sapr-gost/card/type-view", {
          params: {
            weldmethodId: this.welMethodId,
            gostId: this.gostId,
            joinTypeId: this.joinTypeId,
          },
        })
        .then((response) => {
          this.seamAndType = response.data;
          this.typeConnectionId = response.data.seamtypeId;
          this.setCard({ joinType:{name: joinTypeItem.name, id: joinTypeItem.id}})
          this.setCard({ seamAndView:{type: this.seamAndType.seamName, view:this.seamAndType.jointName, seamId: this.typeConnectionId}})
          this.getJointPosition();
        });
    },
    getJointPosition() {
      this.$axios
        .get("/sapr-gost/card/joint-position", {
          params: {
            typeConnectionId: this.typeConnectionId,
          },
        })
        .then((response) => {
          this.jointPositonItems = response.data;
          
        });
    },
  },
};
</script>





