<template>
  <v-card>
    <v-card-title class="justify-center"
      >Характеристика свариваемых элементов</v-card-title
    >
    <v-card-text>
      <div class="inner_image">
        <div class="image">
          <div class="icon">
            <v-icon class="primary--text" x-large
              >mdi-file-image-outline</v-icon
            >
          </div>
          <img :src="image" />
        </div>
      </div>
      <div class="inner__inputs">
        <v-row>
          <v-col>
            <div class="input__header">Характеристика элемента №1</div>
            <div>
              <v-text-field
                @input="setCardElement_1({ typeEl: $event })"
                outlined
                label="Тип элемента №1"
              ></v-text-field>
            </div>
            <div>
              <v-select
                :items="viewItems"
                item-text="name"
                label="Вид детали эелемента №1"
                outlined
                return-object
                @input="hideDOrS_1"
                v-model="view_1"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  <span>{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="primary--text"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span width="200" class="tooltip">{{
                        item.description
                      }}</span>
                    </v-tooltip>
                  </span>
                </template>
              </v-select>
            </div>
            <div>
              <v-text-field
                label="марка материала  №1"
                placeholder="Введите марку стали(сплава)"
                outlined
                @input="setCardElement_1({ markMetal: $event })"
              ></v-text-field>
            </div>
            <div>
              <v-select
                :items="groupItems"
                item-text="name"
                label="Группа материала элемента  №1"
                return-object
                outlined
                @input="setCardElement_1({ groupEl: $event })"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.name }}-{{ item.description }}
                </template>
              </v-select>
            </div>
            <div>
              <v-text-field
                label="Диаметр элемента №1 (D1), мм "
                type="number"
                outlined
                :disabled="hideDiametr_1"
                @input="setCardElement_1({ d: $event })"
                
               
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="Толщина элемента №1 (S), мм"
                type="number"
                outlined
                :disabled="hideFat_1"
                @input="setCardElement_1({ s: $event })"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="Угол элемента №1"
                type="number"
                outlined
                :disabled="hideFat_1"
                @input="setCardElement_1({ corner: $event })"
                v-if="this.card.gost.id == 16 || this.card.gost.id == 14"
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div class="input__header">Характеристика элемента №2</div>
            <div>
              <v-text-field
                @input="setCardElement_2({ typeEl: $event })"
                outlined
                label="Тип элемента №2"
              ></v-text-field>
            </div>
            <div>
              <v-select
                :items="viewItems"
                item-text="name"
                label="Вид детали эелемента №2"
                outlined
                return-object
                @input="hideDOrS_2"
                v-model="view_2"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  <span>{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="primary--text"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span width="200" class="tooltip">{{
                        item.description
                      }}</span>
                    </v-tooltip>
                  </span>
                </template>
              </v-select>
            </div>
            <div>
              <v-text-field
                label="марка материала №2"
                placeholder="Введите марку стали(сплава)"
                outlined
                @input="setCardElement_2({ markMetal: $event })"
              ></v-text-field>
            </div>
            <div>
              <v-select
                :items="groupItems"
                item-text="name"
                label="Группа материала элемента №2"
                return-object
                outlined
                @input="setCardElement_2({ groupEl: $event })"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.name }}-{{ item.description }}
                </template>
              </v-select>
            </div>
            <div>
              <v-text-field
                label="Диаметр элемента №2 (D2), мм"
                type="number"
                outlined
                :disabled="hideDiametr_2"
                @input="setCardElement_2({ d: $event })"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="Толщина элемента №2 (S1), мм"
                type="number"
                outlined
                :disabled="hideFat_2"
                @input="setCardElement_2({ s: $event })"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="Угол элемента №2"
                type="number"
                outlined
                @input="setCardElement_2({ corner: $event })"
                v-if="this.card.gost.id == 16 || this.card.gost.id == 14"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="this.card.error"
          timeout="-1"
          top
          color="error"
        >
        <div class="d-flex justify-space-between">
          <div>{{card.errorMessege}}</div>
        <div>
          <v-btn @click="card.error = false" small text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </div>
        </div>
        
        
        
        </v-snackbar>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      viewItems: [],
      groupItems: [],
      image: null,
      hideDiametr_1: false,
      hideFat_1: false,
      hideDiametr_2: false,
      hideFat_2: false,
      view_1: "",
      view_2: "",
      
      
    };
  },

  computed: {
    ...mapState("gostCard", { card: "newCard" }),
  },
  methods: {
    ...mapMutations("gostCard", {
      setCard: "setNewCardField",
      setCardElement_1: "setNewCardElementField_1",
      setCardElement_2: "setNewCardElementField_2",

   

    }),
    
   
    hideDOrS_1() {
      
      this.setCardElement_1({ viewEl: this.view_1 });
      if (this.card.element_1.viewEl.id == 5) {
        this.hideDiametr_1 = true;
         this.hideFat_1 = false;
      } else if (this.card.element_1.viewEl.id == 6) {
        this.hideFat_1 = true;
        this.hideDiametr_1 = false;
      } else {
        this.hideDiametr_1 = false;
        this.hideFat_1 = false;
      }
    },
     hideDOrS_2() {
      this.setCardElement_2({ viewEl: this.view_2 });
      if (this.card.element_2.viewEl.id == 5) {
        this.hideDiametr_2 = true;
         this.hideFat_2 = false;
      } else if (this.card.element_2.viewEl.id == 6) {
        this.hideFat_2 = true;
        this.hideDiametr_2 = false;
      } else {
        this.hideDiametr_2 = false;
        this.hideFat_2 = false;
      }
    },
  },

  mounted() {
    this.$axios
      .get("/sapr-gost/card/thumb-nails", {
        params: {
          weldmethodId: this.card.weldMethod.id,
          gostId: this.card.gost.id,
          joinTypeId: this.card.joinType.id,
        },
      })
      .then((response) => {
        this.image = response.data.thumbnailEdge;
      });
    this.$axios.get("/sapr-gost/card/view-element").then((response) => {
      this.viewItems = response.data;
    });
    this.$axios.get("/sapr-gost/card/group-element").then((response) => {
      this.groupItems = response.data;
    });
  },
};
</script>
<style scoped>
.inner_image {
  display: flex;
  justify-content: center;
}
.image {
  height: 300px;
  width: 300px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 3px solid #1976d2;
  margin-bottom: 50px;
}
.image img {
  position: absolute;
  width: auto;
  height: 100%;
}
.icon {
  margin: 0 auto;
  font-size: 30px;
}
.inner__inputs {
  max-width: 900px;
  margin: 0 auto;
}
.input__header {
  font-size: 20px;
  color: black;
  margin-bottom: 30px;
}
</style>